
import { defineComponent, PropType, reactive } from 'vue'
import { APIKey } from '@/modules/slowpoke/model/api-keys.model'
import { deleteAPIKey, getAPIKey } from '@/modules/slowpoke/api/api-keys.api'
import {
  FORM_INITIAL_STATE,
  FORM_LOADING_STATE,
  formError,
  FormState,
  formSuccess,
  isFormLoading,
} from '@/utils/form-state'
import FormStateAlert from '@/components/UI/FormStateAlert.vue'
import { ApiError } from '@/api/_shared'
import { useToaster } from '@/composables/useToaster'
import { SlowpokeRoute } from '@/modules/slowpoke/routes'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'APIKeysDetailsTable',
  components: { FormStateAlert },
  props: {
    clientKey: {
      type: String,
      required: true,
    },
    apiKeys: {
      type: Array as PropType<APIKey[]>,
      required: true,
    },
  },
  emits: ['update:apiKeys'],
  setup(props, { emit }) {
    const data = reactive({
      formState: FORM_INITIAL_STATE as FormState,
      deleteApiKey: {
        showModal: false,
        id: 0,
      },
      fullApiKey: {
        show: false,
        value: '',
      },
    })

    const router = useRouter()
    const toaster = useToaster()

    const deleteAPIKeyHandler = async () => {
      try {
        if (data.formState === FORM_LOADING_STATE) {
          return
        }

        data.formState = FORM_LOADING_STATE

        await deleteAPIKey(data.deleteApiKey.id)
        emit(
          'update:apiKeys',
          props.apiKeys.filter((v) => v.id !== data.deleteApiKey.id)
        )

        data.formState = formSuccess(`APIKey was successfully deleted.`)
      } catch (e) {
        data.formState = formError(`Could not delete APIKey. ${e}`, (e as ApiError)?.errors)
      }
    }

    const showApiKey = async (id: number) => {
      try {
        const apiKey = await getAPIKey(id)
        data.fullApiKey = {
          show: true,
          value: apiKey.apiKey,
        }
      } catch (e) {
        toaster && toaster.error(`Could not get the full APIKey.`)
      }
    }

    const copyKeysToClipboard = () => {
      navigator.clipboard.writeText(`X-Client-Key: ${props.clientKey}\nX-API-Key: ${data.fullApiKey.value}`)
      toaster && toaster.success(`Copied to clipboard.`)
    }

    const goToApiKeyStats = (id: number) => {
      router.push({ name: SlowpokeRoute.EditApiKey, params: { id } })
    }

    return { data, deleteAPIKeyHandler, showApiKey, isFormLoading, copyKeysToClipboard, goToApiKeyStats }
  },
})
