
import { computed, defineComponent, PropType, watch } from 'vue'
import PanelSubheading from '@/components/UI/PanelSubheading.vue'
import { APIKey } from '@/modules/slowpoke/model/api-keys.model'
import { isLoaded, isLoading } from '@/utils/loading-state'

export default defineComponent({
  name: 'SlowpokeApiKeyEditor',
  components: {
    PanelSubheading,
  },
  props: {
    apiKey: {
      type: Object as PropType<APIKey>,
      required: true,
    },
    isNewClient: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const localApiKey = computed({
      get: () => props.apiKey,
      set: (value: APIKey) => emit('update:apiKey', value),
    })

    watch(
      () => props.apiKey,
      () => {
        window.scrollTo({ left: 0, top: 0 })
      }
    )

    return {
      localApiKey,
      isLoaded,
      isLoading,
    }
  },
})
