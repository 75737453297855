
import { defineComponent, reactive, watch } from 'vue'
import { errored, isLoaded, loaded, LOADING_STATE, LoadingState, isErrored, isLoading } from '@/utils/loading-state'
import DataLoader from '@/components/UI/DataLoader.vue'
import DataLoadingError from '@/components/UI/DataLoadingError.vue'
import { RouteRecordName, useRoute } from 'vue-router'
import Panel from '@/components/UI/Panel.vue'
import { SlowpokeRoute } from '@/modules/slowpoke/routes'
import GoBack from '@/components/UI/GoBack.vue'
import ApiKeyEditor from '@/modules/slowpoke/components/api-keys/ApiKeyEditor.vue'
import { getAPIKey } from '@/modules/slowpoke/api/api-keys.api'
import { APIKey } from '@/modules/slowpoke/model/api-keys.model'
import PeriodicStats from '@/modules/slowpoke/components/stats/PeriodicStats.vue'

export default defineComponent({
  name: 'SlowpokeApiKeyEditor',
  components: { PeriodicStats, GoBack, Panel, ApiKeyEditor, DataLoadingError, DataLoader },
  setup() {
    const data = reactive({
      apiKey: LOADING_STATE as LoadingState<APIKey>,
    })

    const route = useRoute()

    const loadApiKey = async (id: number) => {
      try {
        data.apiKey = LOADING_STATE
        data.apiKey = loaded(await getAPIKey(id))
      } catch (e) {
        data.apiKey = errored(e)
      }
    }

    type WatchT = [string | string[], RouteRecordName | null | undefined]
    watch(
      (): WatchT => [route.params.id, route.name],
      ([id, name]: WatchT) => {
        if (name === SlowpokeRoute.EditApiKey) {
          loadApiKey(Number(id))
        }
      }
    )

    loadApiKey(Number(route.params.id))

    return {
      data,
      isLoaded,
      isLoading,
      isErrored,
      SlowpokeRoute,
    }
  },
})
