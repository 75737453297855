import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GoBack = _resolveComponent("GoBack")!
  const _component_DataLoader = _resolveComponent("DataLoader")!
  const _component_DataLoadingError = _resolveComponent("DataLoadingError")!
  const _component_ClientEditor = _resolveComponent("ClientEditor")!
  const _component_Panel = _resolveComponent("Panel")!
  const _component_PeriodicStats = _resolveComponent("PeriodicStats")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Panel, { class: "panel" }, {
      header: _withCtx(() => [
        _createVNode(_component_GoBack, {
          to: { name: _ctx.SlowpokeRoute.Clients }
        }, null, 8, ["to"]),
        _createTextVNode(" " + _toDisplayString(_ctx.title), 1)
      ]),
      content: _withCtx(() => [
        _createVNode(_component_DataLoader, {
          "is-loading": _ctx.isLoading(_ctx.data.client)
        }, null, 8, ["is-loading"]),
        _createVNode(_component_DataLoadingError, {
          subject: _ctx.data.client
        }, null, 8, ["subject"]),
        (_ctx.isLoaded(_ctx.data.client))
          ? (_openBlock(), _createBlock(_component_ClientEditor, {
              key: 0,
              client: _ctx.data.client.data,
              "is-new-client": _ctx.data.isNewClient,
              "onUpdate:client": _ctx.updateClient
            }, null, 8, ["client", "is-new-client", "onUpdate:client"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_Panel, { class: "panel" }, {
      header: _withCtx(() => [
        _createTextVNode("TTS usage statistics")
      ]),
      content: _withCtx(() => [
        (_ctx.isLoaded(_ctx.data.client))
          ? (_openBlock(), _createBlock(_component_PeriodicStats, {
              key: 0,
              "subject-type": "client",
              type: "tts",
              "subject-id": _ctx.data.client.data.id
            }, null, 8, ["subject-id"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_Panel, { class: "panel" }, {
      header: _withCtx(() => [
        _createTextVNode("VC usage statistics")
      ]),
      content: _withCtx(() => [
        (_ctx.isLoaded(_ctx.data.client))
          ? (_openBlock(), _createBlock(_component_PeriodicStats, {
              key: 0,
              "subject-type": "client",
              type: "vc",
              "subject-id": _ctx.data.client.data.id
            }, null, 8, ["subject-id"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}