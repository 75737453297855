
import { defineComponent, PropType } from 'vue'
import { useRouter } from 'vue-router'
import { SlowpokeRoute } from '@/modules/slowpoke/routes'
import { Client } from '@/modules/slowpoke/model/clients.model'

export default defineComponent({
  name: 'ClientsDetailsTable',
  props: {
    clients: {
      type: Array as PropType<Client[]>,
      required: true,
    },
  },
  setup() {
    const router = useRouter()

    const navigateToClient = (id: string) => {
      router.push({ name: SlowpokeRoute.EditClient, params: { id } })
    }

    return { navigateToClient }
  },
})
