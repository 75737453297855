
import { computed, defineComponent, PropType, reactive, watch } from 'vue'
import {
  FORM_INITIAL_STATE,
  FORM_LOADING_STATE,
  formError,
  FormState,
  formSuccess,
  isFormErrored,
  isFormLoading,
  isFormSuccess,
} from '@/utils/form-state'
import FormStateAlert from '@/components/UI/FormStateAlert.vue'
import { useRouter } from 'vue-router'
import PanelSubheading from '@/components/UI/PanelSubheading.vue'
import { SlowpokeRoute } from '@/modules/slowpoke/routes'
import { Client, validateClient } from '@/modules/slowpoke/model/clients.model'
import { deleteClient, getSpecialFeatures, updateClient } from '@/modules/slowpoke/api/clients.api'
import { generateClientAPIKey } from '@/modules/slowpoke/api/api-keys.api'
import APIKeysDetailsTable from '@/modules/slowpoke/components/api-keys/APIKeysDetailsTable.vue'
import { APIKey } from '@/modules/slowpoke/model/api-keys.model'
import { merge } from 'lodash'
import { ApiError } from '@/api/_shared'
import { useToaster } from '@/composables/useToaster'
import { errored, loaded, LOADING_STATE, LoadingState, isLoaded, isErrored, isLoading } from '@/utils/loading-state'
import DataLoader from '@/components/UI/DataLoader.vue'
import DataLoadingError from '@/components/UI/DataLoadingError.vue'

export default defineComponent({
  name: 'SlowpokeClientEditor',
  components: {
    DataLoadingError,
    DataLoader,
    APIKeysDetailsTable,
    PanelSubheading,
    FormStateAlert,
  },
  props: {
    client: {
      type: Object as PropType<Client>,
      required: true,
    },
    isNewClient: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const data = reactive({
      formState: FORM_INITIAL_STATE as FormState,
      allSpecialFeatures: LOADING_STATE as LoadingState<string[]>,
      clientSpecialFeatures: props.client.specialFeatures,
      showDeleteModal: false,
      showGenerateAPIKeyModal: false,
    })

    const localClient = computed({
      get: () => props.client,
      set: (value: Client) => emit('update:client', value),
    })

    const updateApiKeys = (apiKeys: APIKey[]) => {
      localClient.value.apiKeys = apiKeys
    }

    const router = useRouter()
    const toaster = useToaster()

    watch(
      () => props.client,
      () => {
        window.scrollTo({ left: 0, top: 0 })
        data.clientSpecialFeatures = props.client.specialFeatures
      }
    )

    const actionHandler = async (actionFn: () => void, noScroll = false) => {
      if (data.formState === FORM_LOADING_STATE) {
        return
      }

      if (!noScroll) {
        window.scrollTo({ left: 0, top: 0 })
      }

      data.formState = FORM_LOADING_STATE

      return actionFn()
    }

    const saveClientHandler = async () => {
      try {
        const clientValidation = validateClient(localClient.value)

        if (clientValidation !== true) {
          data.formState = formError('The form contains errors:', clientValidation)
          return
        }

        localClient.value.specialFeatures = data.clientSpecialFeatures

        localClient.value = merge(localClient.value, await updateClient(localClient.value))
        data.formState = formSuccess(`Client "${localClient.value.name}" has been successfully saved.`)
      } catch (e) {
        data.formState = formError(
          `Client "${localClient.value.name}" could not be saved. ${e}`,
          (e as ApiError)?.errors
        )
      }
    }

    const deleteClientHandler = async () => {
      try {
        await deleteClient(localClient.value)

        const message = `Client "${localClient.value.id}" has been successfully deleted.`
        data.formState = formSuccess(message)
        toaster && toaster.success(message)

        await router.push({ name: SlowpokeRoute.Clients })
      } catch (e) {
        data.formState = formError(
          `Client "${localClient.value.name}" could not be deleted. ${e}`,
          (e as ApiError)?.errors
        )
      }
    }

    const generateAPIKeyHandler = async () => {
      try {
        const newAPIKey = await generateClientAPIKey(Number(localClient.value.id))

        const message = `New API Key has been successfully generated.`
        data.formState = formSuccess(message)
        toaster && toaster.success(message)

        if (Array.isArray(localClient.value.apiKeys)) {
          // eslint-disable-next-line no-unused-expressions
          localClient.value.apiKeys?.push(newAPIKey)
        } else {
          localClient.value.apiKeys = [newAPIKey]
        }
      } catch (e) {
        data.formState = formError(`Could not generate new API Key. ${e}`, (e as ApiError)?.errors)
      }
    }

    const loadData = async () => {
      try {
        data.allSpecialFeatures = LOADING_STATE
        data.allSpecialFeatures = loaded(await getSpecialFeatures())
      } catch (e) {
        data.allSpecialFeatures = errored(e)
      }
    }

    const updateSpecialFeatures = async (feature: string, value: boolean) => {
      const specialFeatures = data.clientSpecialFeatures.filter((f: string) => f !== feature)

      if (value) {
        specialFeatures.push(feature)
      }

      data.clientSpecialFeatures = specialFeatures
    }

    loadData()

    return {
      data,
      localClient,
      actionHandler,
      saveClientHandler,
      deleteClientHandler,
      generateAPIKeyHandler,
      isFormLoading,
      isFormErrored,
      isFormSuccess,
      toaster,
      updateApiKeys,
      isLoaded,
      isLoading,
      isErrored,
      updateSpecialFeatures,
    }
  },
})
