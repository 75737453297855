
import { computed, defineComponent, reactive, watch } from 'vue'
import { errored, isLoaded, loaded, LOADING_STATE, LoadingState, isErrored, isLoading } from '@/utils/loading-state'
import DataLoader from '@/components/UI/DataLoader.vue'
import DataLoadingError from '@/components/UI/DataLoadingError.vue'
import { RouteRecordName, useRoute } from 'vue-router'
import Panel from '@/components/UI/Panel.vue'
import { SlowpokeRoute } from '@/modules/slowpoke/routes'
import GoBack from '@/components/UI/GoBack.vue'
import ClientEditor from '@/modules/slowpoke/components/clients/ClientEditor.vue'
import { Client } from '@/modules/slowpoke/model/clients.model'
import { getClient } from '@/modules/slowpoke/api/clients.api'
import PeriodicStats from '@/modules/slowpoke/components/stats/PeriodicStats.vue'

export default defineComponent({
  name: 'SlowpokeClientEditor',
  components: { PeriodicStats, GoBack, Panel, ClientEditor, DataLoadingError, DataLoader },
  setup() {
    const data = reactive({
      isNewClient: false,
      client: LOADING_STATE as LoadingState<Client>,
    })

    const route = useRoute()

    const title = computed(() => {
      if (isLoaded(data.client)) {
        return 'Client: ' + `${data.client.data.name}`
      } else {
        return 'Client details'
      }
    })

    const loadClient = async (id: number) => {
      try {
        data.client = LOADING_STATE

        data.client = loaded(await getClient(id))
        data.isNewClient = false
      } catch (e) {
        data.client = errored(e)
      }
    }

    const updateClient = (client: Client) => {
      data.client = loaded(client)
    }

    type WatchT = [string | string[], RouteRecordName | null | undefined]
    watch(
      (): WatchT => [route.params.id, route.name],
      ([id, name]: WatchT) => {
        if (name === SlowpokeRoute.EditClient) {
          loadClient(Number(id))
        }
      }
    )

    loadClient(Number(route.params.id))

    return {
      data,
      title,
      isLoaded,
      isLoading,
      isErrored,
      SlowpokeRoute,
      updateClient,
    }
  },
})
