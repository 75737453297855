import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PanelSubheading = _resolveComponent("PanelSubheading")!
  const _component_va_input = _resolveComponent("va-input")!
  const _component_va_form = _resolveComponent("va-form")!

  return (_openBlock(), _createBlock(_component_va_form, { class: "mb-5" }, {
    default: _withCtx(() => [
      _createVNode(_component_PanelSubheading, { class: "mb-4 mt-4" }, {
        default: _withCtx(() => [
          _createTextVNode("ApiKey data")
        ]),
        _: 1
      }),
      _createVNode(_component_va_input, {
        bordered: "",
        class: "mb-4",
        label: "ID",
        modelValue: _ctx.localApiKey.id,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localApiKey.id) = $event)),
        disabled: ""
      }, null, 8, ["modelValue"]),
      _createVNode(_component_va_input, {
        bordered: "",
        class: "mb-4",
        label: "Client ID",
        modelValue: _ctx.localApiKey.clientId,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localApiKey.clientId) = $event)),
        disabled: ""
      }, null, 8, ["modelValue"]),
      _createVNode(_component_va_input, {
        bordered: "",
        class: "mb-4",
        label: "ApiKey",
        modelValue: _ctx.localApiKey.apiKey,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localApiKey.apiKey) = $event)),
        disabled: ""
      }, null, 8, ["modelValue"])
    ]),
    _: 1
  }))
}