
import { defineComponent, reactive } from 'vue'
import { errored, isLoaded, loaded, LOADING_STATE, LoadingState, isErrored, isLoading } from '@/utils/loading-state'
import DataLoader from '@/components/UI/DataLoader.vue'
import DataLoadingError from '@/components/UI/DataLoadingError.vue'
import Panel from '@/components/UI/Panel.vue'
import { getAllClients } from '@/modules/slowpoke/api/clients.api'
import { Client } from '@/modules/slowpoke/model/clients.model'
import ClientsDetailsTable from '@/modules/slowpoke/components/clients/ClientsDetailsTable.vue'

export default defineComponent({
  name: 'SlowpokeClients',
  components: { ClientsDetailsTable, Panel, DataLoadingError, DataLoader },
  setup() {
    const data = reactive({
      clients: LOADING_STATE as LoadingState<Client[]>,
    })

    const loadClients = async () => {
      try {
        data.clients = LOADING_STATE
        data.clients = loaded(await getAllClients())
      } catch (e) {
        data.clients = errored(e)
      }
    }

    loadClients()

    return {
      data,
      isLoaded,
      isLoading,
      isErrored,
    }
  },
})
