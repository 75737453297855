import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-08871593"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "special-features"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormStateAlert = _resolveComponent("FormStateAlert")!
  const _component_PanelSubheading = _resolveComponent("PanelSubheading")!
  const _component_va_input = _resolveComponent("va-input")!
  const _component_va_button = _resolveComponent("va-button")!
  const _component_APIKeysDetailsTable = _resolveComponent("APIKeysDetailsTable")!
  const _component_DataLoader = _resolveComponent("DataLoader")!
  const _component_DataLoadingError = _resolveComponent("DataLoadingError")!
  const _component_va_checkbox = _resolveComponent("va-checkbox")!
  const _component_va_separator = _resolveComponent("va-separator")!
  const _component_va_form = _resolveComponent("va-form")!
  const _component_va_modal = _resolveComponent("va-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_FormStateAlert, {
      "form-state": _ctx.data.formState
    }, null, 8, ["form-state"]),
    _createElementVNode("div", null, [
      _createVNode(_component_va_form, { class: "mb-5" }, {
        default: _withCtx(() => [
          _createVNode(_component_PanelSubheading, { class: "mb-3 mt-4" }, {
            default: _withCtx(() => [
              _createTextVNode("Client data")
            ]),
            _: 1
          }),
          (!_ctx.isNewClient)
            ? (_openBlock(), _createBlock(_component_va_input, {
                key: 0,
                bordered: "",
                class: "mb-3",
                label: "ID",
                modelValue: _ctx.localClient.id,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localClient.id) = $event)),
                disabled: ""
              }, null, 8, ["modelValue"]))
            : _createCommentVNode("", true),
          _createVNode(_component_va_input, {
            bordered: "",
            class: "mb-3",
            label: "Email",
            modelValue: _ctx.localClient.email,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localClient.email) = $event)),
            disabled: !_ctx.isNewClient
          }, null, 8, ["modelValue", "disabled"]),
          (!_ctx.isNewClient)
            ? (_openBlock(), _createBlock(_component_va_input, {
                key: 1,
                bordered: "",
                class: "mb-3",
                label: "Key",
                modelValue: _ctx.localClient.key,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localClient.key) = $event)),
                disabled: ""
              }, null, 8, ["modelValue"]))
            : _createCommentVNode("", true),
          _createVNode(_component_va_input, {
            bordered: "",
            class: "mb-3",
            label: "Name",
            modelValue: _ctx.localClient.name,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.localClient.name) = $event))
          }, null, 8, ["modelValue"]),
          _createVNode(_component_va_input, {
            bordered: "",
            class: "mb-3",
            label: "Balance",
            modelValue: _ctx.localClient.balance,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localClient.balance) = $event)),
            disabled: ""
          }, null, 8, ["modelValue"]),
          (!_ctx.isNewClient)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                _createVNode(_component_PanelSubheading, { class: "mb-3 mt-4" }, {
                  default: _withCtx(() => [
                    _createTextVNode(" API Keys "),
                    _createVNode(_component_va_button, {
                      class: "ml-4",
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.data.showGenerateAPIKeyModal = true)),
                      color: "success"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Generate new")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_APIKeysDetailsTable, {
                  "client-key": _ctx.client.key,
                  "api-keys": _ctx.client.apiKeys,
                  "onUpdate:apiKeys": _ctx.updateApiKeys
                }, null, 8, ["client-key", "api-keys", "onUpdate:apiKeys"]),
                _createVNode(_component_PanelSubheading, { class: "mb-3 mt-4" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Special features")
                  ]),
                  _: 1
                }),
                _createVNode(_component_DataLoader, {
                  "is-loading": _ctx.isLoading(_ctx.data.allSpecialFeatures)
                }, null, 8, ["is-loading"]),
                _createVNode(_component_DataLoadingError, {
                  subject: _ctx.data.allSpecialFeatures
                }, null, 8, ["subject"]),
                (_ctx.isLoaded(_ctx.data.allSpecialFeatures))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.allSpecialFeatures.data, (feature) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "special-features__item",
                          key: feature
                        }, [
                          _createVNode(_component_va_checkbox, {
                            class: "special-features__checkbox",
                            "model-value": _ctx.data.clientSpecialFeatures.includes(feature),
                            "onUpdate:modelValue": (value) => _ctx.updateSpecialFeatures(feature, value)
                          }, null, 8, ["model-value", "onUpdate:modelValue"]),
                          _createTextVNode(" " + _toDisplayString(feature), 1)
                        ]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true),
                _createVNode(_component_va_separator),
                _createVNode(_component_va_button, {
                  class: "mr-2",
                  onClick: _cache[6] || (_cache[6] = () => _ctx.actionHandler(_ctx.saveClientHandler)),
                  disabled: _ctx.isFormLoading(_ctx.data.formState)
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Save ")
                  ]),
                  _: 1
                }, 8, ["disabled"]),
                _createVNode(_component_va_button, {
                  class: "mr-2",
                  onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.data.showDeleteModal = true)),
                  color: "danger",
                  disabled: _ctx.isFormLoading(_ctx.data.formState)
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Delete ")
                  ]),
                  _: 1
                }, 8, ["disabled"])
              ], 64))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_va_modal, {
        modelValue: _ctx.data.showGenerateAPIKeyModal,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.data.showGenerateAPIKeyModal) = $event)),
        title: "Confirm",
        message: "Are you sure you want to generate new API Key?",
        onOk: _cache[9] || (_cache[9] = () => _ctx.actionHandler(_ctx.generateAPIKeyHandler, true))
      }, null, 8, ["modelValue"]),
      _createVNode(_component_va_modal, {
        modelValue: _ctx.data.showDeleteModal,
        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.data.showDeleteModal) = $event)),
        title: "Confirm",
        message: "Are you sure you want to delete this client?",
        onOk: _cache[11] || (_cache[11] = () => _ctx.actionHandler(_ctx.deleteClientHandler))
      }, null, 8, ["modelValue"])
    ])
  ], 64))
}